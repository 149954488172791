import React from "react";

const config = require("../../../config.json")
const client = config.APP_CLIENT;
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;

const Mail = {
  margin: "0px 0px 0px 0px",
  fontSize: "15px",
  lineHeight: "1.38",
  textDecoration: "none",
  paddingTop: "3px",
};
const MailLink = {
  textDecoration: "none",
  color: compagnyColorPrimary,
  fontSize: "15px",
  margin: "0px 0px 0px",
};
const iconLogo = {
  marginRight: "10px",
  transform: "translateY(5px)",
};

export default function Email(email) {
  return (
    <p style={Mail}>
      <img src={`https://files.myclientisrich.com/${client}/mail.png`} style={iconLogo} alt="logo" width="20" height="15"></img>
      <a href={`mailto:${email.email}`} style={MailLink}>
        {email.email}
      </a>
    </p>
  );
}

import React from "react";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";
import { shape, string } from "prop-types";
import Email from "./items/email";
import Phone from "./items/phone";
import Fax from "./items/fax";
import PostalAdress from "./items/postalAdress";
import Instagram from "./items/instagram";
import Facebook from "./items/facebook";
import Linkedin from "./items/linkedin";

const config = require("../../config.json")
const client = config.APP_CLIENT;
const imgName = config.APP_IMG_NAME;
const compagnyName = config.APP_COMPAGNY_NAME;
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;
const colorBackgroundTable = config.APP_COLORBACKGROUNDTABLE;
const widthImage = config.APP_WIDTHIMAGE;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(35vh);
  @media (max-width: 1280px) {
    transform: translateY(0vh);
  }
`;
const ContainerSocial = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  margin: "0px",
};
const SignatureStyle = {
  borderSpacing: "20px",
  backgroundColor: colorBackgroundTable,
};
const ImageWrapper = {
  // display: "block",
  position: "relative",
  width: widthImage,
  height: widthImage,
  marginLeft: "20px",
  objectFit: "cover",
  minWidth: "200px",
};
const imgLeft = {
  position: "absolute",
  height: widthImage,
  width: widthImage,
  top: "0",
};
const imgLeftCenter = {
  position: "absolute",
  height: widthImage,
  width: widthImage,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
const Image = {
  width: widthImage,
  height: "100%",
  margin: "auto",
  display: "block",
};
const ImageObject = {
  width: widthImage,
  height: "100%",
  margin: "auto",
  display: "block",
  objectFit: "cover",
};
const TextWrapper = {
  marginLeft: "20px",
  fontFamily: "Arial",
  lineHeight: "1.45",
  minWidth: "200px",
};
const Name = {
  fontSize: "20px",
  margin: "0",
  fontWeight: "700",
  lineHeight: "1",
  display: "inline-block",
};
const Job = {
  margin: "3px 0px 5px 0px",
  fontSize: "12px",
  letterSpacing: "1px",
  color: compagnyColorPrimary,
  fontWeight: "700",
  textTransform: "uppercase",
  width: "250px",
};
const Pole = {
  margin: "0px 0px 5px",
  fontSize: "15px",
  lineHeight: "1.15",
  color: compagnyColorPrimary,
  fontWeight: "bold",
};
const Link = {
  color: "rgb(200, 174, 102)",
  fontWeight: "bold",
  fontSize: "15px",
};
const StandardURL = {
  margin: "10px 0px 10px 0px",
  fontSize: "15px",
};
const Slogan = {
  margin: "10px 0 10px 0",
  fontSize: "13px",
  fontStyle: "italic",
  lineHeight: "1.38",
  color: compagnyColorPrimary,
  fontWeight: "bold",
  whiteSpace: "pre-line",
};
const name = {
  display: "flex",
  flexDirection: "row",
}

function constructImgUrl(client, ext) {
  if (ext) {
    return `https://files.myclientisrich.com/${client}/${imgName}.${ext}`;
  }
}

const SignatureFormatter = ({ user, message, file, typeImage }) => {
  let urlUploadsImg = "";
  if (user.urlImage === "" || user.urlImage === null) {
    if (typeImage === "static") {
      urlUploadsImg = constructImgUrl(client, 'jpg');
    } else if (typeImage === "gif") {
      urlUploadsImg = constructImgUrl(client, 'gif');
    }
  }

  let positionImage = false;
  let positionLeft;
  if (message === true) {
    positionImage = ImageObject;
    positionLeft = imgLeftCenter
  } else {
    positionImage = Image;
    positionLeft = imgLeft
  }

  return (
    <Wrapper>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {compagnyName} | Signature
        </title>
      </Helmet>
      <div className='wrapper-table'>
        <table className="signature" style={SignatureStyle}>
          <tbody>
            <tr>
              <td className="image__wrapper" style={ImageWrapper}>
                {user.urlImage ? (
                  <a style={positionLeft} href={`https://${user.urlImage}`} target='_blank' rel="noreferrer">
                    <img src={urlUploadsImg} style={positionImage} alt="logo" />
                  </a>
                ) : (
                  <a style={positionLeft} href={`https://${user.urlImage}`} target='_blank' rel="noreferrer">
                    <img src={urlUploadsImg} style={positionImage} alt="logo" />
                  </a>
                )}
              </td>
              <td className="text__wrapper" style={TextWrapper}>
                <p style={name}>
                  <p style={Object.assign({}, Name, { color: compagnyColorPrimary })}>
                    {user.firstname}
                    &nbsp;
                  </p>
                  <p style={Object.assign({}, Name, { color: compagnyColorPrimary })}>
                    {user.lastname}
                  </p>
                </p>
                <h4 style={Job}>{user.job}</h4>
                {user.availability ? (
                  <p style={Pole}>{user.availability}</p>
                ) : null}
                {user.postalAdress ? (
                  <PostalAdress adress={user.postalAdress} />
                ) : null}
                {user.email ? (
                  <Email email={user.email} />
                ) : null}
                {user.phone ? (
                  <Phone phone={user.phone} />
                ) : null}
                {user.fax ? (
                  <Fax fax={user.fax} />
                ) : null}
                {user.slogan ? (
                  <p style={Slogan}
                    dangerouslySetInnerHTML={{ __html: user.slogan }}>
                  </p>
                ) : null}
                <p style={StandardURL}>
                  <a href={user.website} style={Link}>{user.website}</a>
                </p>
                <p style={ContainerSocial}>
                  {user.linkedin ? (
                    <Linkedin linkedin={user.linkedin} />
                  ) : null}
                  {user.facebook ? (
                    <Facebook facebook={user.facebook} />
                  ) : null}
                  {user.instagram ? (
                    <Instagram instagram={user.instagram} />
                  ) : null}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Wrapper>
  );
};

SignatureFormatter.propTypes = {
  user: shape({
    name: string.isRequired,
    job: string.isRequired,
    availability: string,
    phone: string
  }).isRequired
};

export default SignatureFormatter;

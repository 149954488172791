import React from "react";

const config = require("../../../config.json")
const client = config.APP_CLIENT;
const compagnyColorPrimary = config.APP_COMPAGNYCOLORPRIMARY;

const PhoneLink = {
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  color: compagnyColorPrimary,
  paddingRigth: "5px",
  marginRigth: "5px",
  lineHeight: "0",
};
const imgSocial = {
  paddingRight: "10px",
};

export default function Linkedin(linkedin) {
  return (
    <>
      <a href={`${linkedin.linkedin}`}
        style={PhoneLink}
      >
        <img style={imgSocial} src={`https://files.myclientisrich.com/${client}/linkedin.png`} alt="logo"></img>
      </a>
      <br />
    </>
  );
}
